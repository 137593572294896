$iframe-offset: 1.25rem; // don't know why, but moodle iframe needs an offset

.overlay {
  width: 64rem;
  height: 42rem;
  position: relative;

  overflow: hidden;
  margin: 0.9375rem auto;
  max-width: 64rem;
}

.moodle-does-not-work-overlay {
  z-index: 100;
  position: absolute;
  left: 0;
  width: inherit;
  height: inherit;
  background-color: rgba(204, 204, 204, 0.7);
  top: 0;
}

#moodle-content {
  position: absolute;
  left: 0;
  width: inherit;
  height: inherit;

  top: -4.3125rem; /* clipping top of page */
  width: calc(
    64rem + #{$iframe-offset}
  ); /* set this to approximate width of entire page you're embedding */
  height: 47.1875rem; /* determines where the bottom of the page cuts off */
  margin-left: 0rem; /* clipping left side of page */
  overflow: hidden;

  /* resize seems to inherit in at least Firefox */
  -webkit-resize: none;
  -moz-resize: none;
  resize: none;
}
