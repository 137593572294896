.billing {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.documentText {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  text-align: start;
  padding: 1.25rem 0 2.5rem 0;
}

.CheckoutHeading {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 1.25rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--text-color);
  text-transform: uppercase;
}

.uploadStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  margin: 1.25rem 0 2.5rem 0;

  button:first-of-type {
    align-items: flex-start;
    width: 100%;
    margin: 1.25rem 0 1.25rem 0;
  }
}

.noteBox {
  padding: 1rem;
  border: 0.125rem solid var(--primary-color);
}
