@page {
  size: auto; /* auto is the initial value */
  margin: 1.87rem 1.87rem 1.25rem 1.87rem; /* this affects the margin in the printer settings */
}

.myeventslist-print {
  &-title {
    font-size: 1.62rem;
    font-weight: bold;
  }

  &-date {
    font-size: 1.62rem;
    margin-top: -0.31rem;
  }

  &-lable {
    font-weight: bold;
    padding: 0.31rem 0;
  }

  &-value {
    padding: 0.31rem 0;
  }

  &-tablecell > th {
    font-size: 1rem;
    font-weight: bold;
    padding: 0.31rem;
    height: 2.06rem;
    border: 0.06rem solid var(--text-color);
  }

  &-tablecell > td {
    font-size: 0.87rem !important;
    padding: 0.31rem !important;
    height: 1.75rem;
    border: 0.06rem solid var(--text-color) !important;
  }
}
