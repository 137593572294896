@mixin reset-list {
  list-style-position: inside;
  list-style: none;
}

@mixin indent-list {
  text-indent: -1.4375rem;
  padding-left: 1.4375rem;
}

@mixin base-list {
  display: inline-block;
  margin-right: 1rem;
  width: 0.4375rem;
  height: 0.4375rem;
  background-color: var(--text-color);
  border-radius: 50%;
}

@mixin custom-lumos-list {
  @include indent-list;
  li {
    @include reset-list;
  }

  li::before {
    @include base-list;
    content: "";
  }
}

@mixin custom-lumos-checkmark-list {
  @include indent-list;
  li {
    @include reset-list;
  }

  li::before {
    @include base-list;
    content: "\f109";
    font-family: "22_iconfont";
  }
}

@mixin custom-lumos-ordered-list {
  li {
    list-style: outside;
    list-style-type: decimal;
    width: calc(100% - 0.9375rem);
    -webkit-transform: translateX(0.9375rem);
    -ms-transform: translateX(0.9375rem);
    transform: translateX(0.9375rem);
    padding-left: 0.5rem;
    text-indent: 0;
  }

  li::before {
    content: none;
  }
}
