.icon-arrow-right::after {
  content: "\f101";
  font-family: "22_iconfont", sans-serif;
  display: inline;
  margin: 0 0.5rem;
}

.icon-download::after {
  content: "\f103";
  font-family: "22_iconfont", sans-serif;
  display: inline;
  margin: 0 0.5rem;
}

.float-right {
  float: right;
}

.alphabetical-list li {
  list-style-type: lower-alpha;
}

.large-link {
  font-size: 1.1rem;
}

.large-link::after {
  font-size: 1.1rem;
}