@import "src/styles/variables";

.participant {
  &-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.12);
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    border-bottom: 0.125rem solid rgb(0, 0, 0, 0.3);
  }

  &-list-secondarytext {
    color: $secondary-accent-1;
  }

  &-list-count-badge {
    margin-left: 0.625rem;
  }

  &-list-count-badge > span {
    height: 1.5625rem !important;
    padding: 0 0.5rem !important;
    border-radius: 0.9375rem !important;
    font-size: 0.93rem !important;
  }

  &-reactivated {
    display: flex;
    flex-direction: column;
  }

  &-reactivated svg {
    margin: 0.0625rem;
  }
}

svg.Mui-disabled {
  stroke: transparent !important;
}
