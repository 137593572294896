@import "../../../styles/variables";

.pinboard {
  &-muted {
    color: $grey;
  }

  &-self {
    font-weight: bold;
  }

  &-muted-mono {
    color: #000;
  }

  &-details {
    float: right;
    padding: 0.625rem 1.0625rem;
    font-weight: bold;
  }

  &-card {
    border-radius: 0 !important;
    box-shadow: 0rem 0.625rem 1.25rem -0.3125rem rgba(0, 0, 0, 0.2) !important;
    margin: 1.25rem;
    width: stretch;
  }

  &-card-mono {
    border-radius: 0 !important;
    margin: 1.25rem;
    border: 0.0625rem solid #000;
    width: stretch;
  }

  &-cardheader,
  &-cardheader-mono,
  &-cardheader-archived,
  &-cardheader-mono-archived {
    padding: 0.4375rem 0.4375rem 0.4375rem 1.0625rem !important;
  }

  &-cardheader span {
    line-height: 1;
    min-height: 1.875rem;
    padding-top: 0.1875rem;
  }

  &-cardheader,
  &-cardheader-archived {
    background-color: $orange;
    box-shadow: 0rem 0.625rem 1.25rem -0.3125rem rgba(0, 0, 0, 0.1) !important;
  }

  &-cardheader {
    background-color: $orange;
  }

  &-cardheader-archived {
    background-color: $grey;
  }

  &-cardheader-author {
    background-color: $berry;
    color: #fff;
  }

  &-cardheader span,
  &-cardheader-archived span {
    color: #fff;
  }

  &-cardheader-mono {
    border-bottom: 0.0625rem solid #000;
    background-color: #fff;
  }

  &-cardheader-mono-archived {
    background-color: #000;
  }

  &-cardheader-mono-archived span {
    color: #fff;
  }

  &-cardheader-mono span {
    color: #000;
  }

  &-cardheader-mono svg {
    color: #000;
  }

  &-button,
  &-button-mono {
    margin: 5% 0 5% 5% !important;
    text-align: end;
    display: flex;
    padding-right: 1em;
  }

  &-button-mono button:hover,
  &-sub-button-mono button:hover {
    color: #fff;
    background-color: #000 !important;
  }

  &-button-mono button:hover svg,
  &-sub-button-mono button:hover svg {
    color: #fff;
  }

  &-post-details-icon {
    cursor: pointer;
  }

  &-post-expand-icon {
    transition: ease-in-out 0.2s !important;
    transform: rotate(90deg);
  }

  &-post-shrink-icon {
    transition: ease-in-out 0.2s !important;
    transform: rotate(-90deg);
  }
}
