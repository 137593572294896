@import "../../../../../styles/_custom-mixins.scss";
@import "../../../../../styles/variables";

.event-item {
  ul {
    @include custom-lumos-list;
  }

  ol {
    @include custom-lumos-ordered-list;
  }

  &-title {
    font-weight: bold;
    font-size: 1.625rem;
  }

  &-info-small {
    font-size: 0.75rem;
    margin-right: 1.25rem;
  }

  &-status-dot {
    height: 1.5625rem;
    width: 1.5625rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    border-radius: 50%;
    display: inline-block;
  }

  &-dot-green {
    background-color: #95e917;
  }

  &-dot-red {
    background-color: #d71541;
  }

  &-dot-yellow {
    background-color: #f49325;
  }

  &-dot-mono-published {
    background-color: #fff;
    border: 1.125rem solid #000;
  }

  &-dot-mono-full {
    background-color: #000;
  }

  &-dot-mono-almost-full {
    background-color: #000;
    background: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 0.1875rem,
      #000 0.1875rem,
      #000 0.375rem
    );
  }

  &-dot-grey {
    background-color: $grey;
  }

  &-details-icon {
    cursor: pointer;
  }

  &-expand-icon {
    transition: ease-in-out 0.2s !important;
    transform: rotate(90deg);
  }

  &-shrink-icon {
    transition: ease-in-out 0.2s !important;
    transform: rotate(-90deg);
  }

  &-status-text {
    text-align: center;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 37.5rem) and (min-width: 0rem) {
  .event-item {
    &-title {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 60rem) and (min-width: 37.5625rem) {
  .event-item {
    &-title {
      font-weight: bold;
      font-size: 1.25rem;
    }
  }
}
