@import "../../../styles/variables";

.shopping-cart {
  @media only screen and (min-width: 37.5625rem) {
    &-wrapper {
      padding: 1.25rem;
    }
    &-bookingbutton-container {
      margin-top: 1.25rem;
    }

    &-tablebody {
      max-height: 37.5rem;
    }
  }
  @media only screen and (max-width: 37.5rem) {
    &-wrapper {
      padding: 0.625rem;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-tablebody td {
      padding: 0.5rem;
    }
  }

  @media only screen and (max-width: 60rem) {
    &-bookingbutton-container {
      margin-top: 1.25rem;
    }
  }

  &-icon {
    position: fixed;
    @media only screen and (max-width: 60rem) {
      left: calc(100vw - 3.6875rem);
    }
    right: 0;
    z-index: 1000; // needs to be greater than scroll to top button
    top: 12.5rem;
  }

  &-icon-mono {
    position: fixed;
    @media only screen and (max-width: 60rem) {
      left: calc(100vw - 3.6875rem);
    }
    right: 0;
    z-index: 1000; // needs to be greater than scroll to top button
    top: 12.5;
    background-color: #000;
    color: #fff;
    border:
      0,
      0625rem solid #000;
  }

  &-bookingbutton-container {
    text-align: right;
  }

  &-tablebody {
    overflow-y: auto;
  }

  &-emptyinfocell {
    border-bottom: 0 !important;
  }
}
/*
.MuiBadge-colorPrimary {
  color: #fff !important;
  background-color: $orange !important;
}*/
