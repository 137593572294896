@import "../../../../styles/variables";

.speakers-list {
  &-added {
    overflow-y: scroll;
    height: 9.37rem;
    margin-bottom: 1.87rem;
  }

  &-select {
    overflow-y: scroll;
    height: 18.75rem;
  }

  &-item {
    padding: 1.25rem 1.25rem 1.25rem 0.62rem;
    border-bottom: 0.06rem solid rgba(0, 0, 0, 0.12);

    &-col {
      margin: auto !important;
    }

    &-addButton {
      padding: 0.32rem 1.06rem !important;
      width: 0rem !important;
      min-width: 0rem !important;
    }
  }
}
