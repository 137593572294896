.scrollTop {
  position: fixed !important;
  right: 0;
  bottom: -3.125rem;
  z-index: 500;
  opacity: 1;
  height: 3.125rem;
  width: 3.125rem;
}

.show-button {
  animation: fadeOut 1s ease;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.hide-button {
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  0% {
    -webkit-transform: translateY(0rem);

    bottom: 8.5rem;
  }
  100% {
    -webkit-transform: translateY(12.5rem);
    bottom: 0;
  }
}

@keyframes fadeOut {
  0% {
    -webkit-transform: translateY(12.5rem);
    bottom: 0;
  }
  100% {
    -webkit-transform: translateY(0rem);
    bottom: 8.5rem;
  }
}
