@page {
  size: A4 !important;
  /* portrait only (landscape orientation can break the table layout) */
  margin: 1.875rem 1.875rem 1.25rem 1.875rem;
  /* this affects the margin in the printer settings */
}

.participantlist-print {
  &-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.625rem;
  }

  &-lable {
    font-weight: bold;
    padding: 0.3125rem 0;
  }

  &-value {
    padding: 0.3125rem 0;
  }

  &-tablecell > th {
    font-size: 1rem;
    font-weight: bold;
    padding: 0.3125rem;
    height: 2.0625rem;
    width: 11.0625rem;
    border: 0.0625rem solid var(--text-color);
  }

  &-tablecell > td {
    font-size: 0.75rem;
    padding: 0.3125rem;
    height: 1.75rem;
    border: 0.0625rem solid var(--text-color);
  }
}
