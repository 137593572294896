@import "./../../../../styles/variables";

#main-menu-button {
  color: $grey;
  font-size: 3.125rem;
}

#main-menu-button-mono {
  color: #fff;
  font-size: 3.125rem;
}

.main-menu-wrapper {
  text-align: right;
}
