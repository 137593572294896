@import "src/styles/variables";

.preview {
  &-event-box {
    padding: 1.25rem 0.5rem;
    margin-bottom: 0.625rem;
    box-shadow:
      0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2),
      0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14),
      0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12);
  }

  &-task {
    &-list {
      &-header {
        font-weight: 700;
        border-bottom: 0.0625rem solid $grey;
        padding: 1rem 0;
      }

      &-item {
        display: flex;
        align-items: center;
        padding: 0.625rem 0;

        &-delButton {
          cursor: pointer;
          color: $red;
        }

        &-delButton-mono {
          cursor: pointer;
          color: #000;
        }
      }

      &-item:hover {
        background-color: $secondary-accent-2;
      }
    }
  }
}
