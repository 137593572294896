// TODO: this needs to be refactored and adapted to material ui

/* ---------------- INPUT FIELDS AND FORM ELEMENTS ---------------- */

.input-field > label {
  -webkit-transform: translateY(1.25rem) !important;
  transform: translateY(1.25rem) !important;
  left: 1.25rem !important;
}

.input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(0rem) scale(0.8) !important;
  transform: translateY(0rem) scale(0.8) !important;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  top: 0.3125rem !important;
}

/* Use for custom icon prefix as suffix */
.input-field .prefix.search {
  top: 1rem;
  right: 0.5rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a2a2a2;
  opacity: 1; /* Firefox */
}

.datepicker-controls .select-month input {
  width: 4.375rem !important;
  border-bottom: none !important;
}

.datepicker-controls .select-year input {
  width: 3.125rem !important;
  border-bottom: none !important;
}

.datepicker-calendar > .select-wrapper input.select-dropdown {
  margin: 0 !important;
}

.month-prev:focus,
.month-next:focus {
  background-color: #0094ff;
}

.timepicker-close {
  color: var(--secondary-color) !important;
}

.timepicker-digital-display {
  background-color: var(--secondary-color) !important;
}

.timepicker-canvas-bg,
.timepicker-canvas-bearing {
  fill: var(--secondary-color) !important;
}

.timepicker-canvas line {
  stroke: var(--secondary-color) !important;
}

#editor {
  overflow-y: auto;
}

.focused {
  border: 0.125rem solid var(--secondary-color) !important;
}

.focused-mono {
  border: 0.125rem solid #000 !important;
}
