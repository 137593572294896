@import "../../../styles/_variables.scss";

@mixin header-logo {
  background-color: #fff;
  padding-top: 0.625rem;
  max-height: 5rem;
  min-width: 15rem;
  margin-top: 0.28rem;
  border: none;
}

.navbar-header-logo {
  @include header-logo();
}

.navbar-header-logo-mono {
  @include header-logo();
  filter: grayscale(1) brightness(0.5) contrast(2) brightness(2);
}

@media screen and (min-width: 37.5rem) {
  .navigation-logo {
    padding: 0 0.25rem !important;
  }
}

@media screen and (max-width: 37.5rem) {
  .navbar-logo-button {
    margin-left: -2rem;
    margin-top: -0.2rem !important;
    padding-top: 0.2rem !important;
  }

  .navbar-header-logo,
  .navbar-header-logo-mono {
    padding-top: 0rem;
    height: 3.7rem;
    margin-top: 0rem;

  }
}

.box-shadow {
  box-shadow:
    0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2),
    0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14),
    0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12);
}

header {
  width: 100%;
  height: 6.125rem;
  position: sticky;
  top: 0;

  background-color: white;
  z-index: 999;
}

.nav-item {
  padding: 0.3125rem 0.625rem;

  &-default {
    a {
      color: $text-color;

      &:hover {
        color: $orange;
      }
    }
  }
}