@import "src/styles/variables";

.event-templates {
  &-list {
    &-header {
      font-weight: 700;
      border-bottom: 0.0625rem solid $grey;
      padding: 1rem 0;
    }

    &-item {
      padding: 0.625rem 0;
    }

    &-item:hover {
      background-color: $secondary-accent-2;
    }
  }
}
