@import "../../../styles/_custom-mixins.scss";
@import "../../../styles/variables";

.myevents {
  &-description {
    ul {
      @include custom-lumos-list;
    }

    ol {
      @include custom-lumos-ordered-list;
    }
  }

  &-list {
    &-header {
      font-weight: 700;
      border-bottom: 0.0625rem solid $grey;
      margin-top: 0.875rem !important;
      padding: 1rem 0;
    }

    &-item {
      padding: 1rem 0;
    }

    &-item:hover {
      background-color: $secondary-accent-2;
    }

    &-item a {
      color: #000;
    }

    &-item a .svg-icon {
      vertical-align: top;
    }
  }

  &-details {
    &-headline {
      font-size: 1.17rem;
      font-weight: bold;
      margin-top: 0.625rem;
    }

    &-property {
      &-label {
        font-weight: 700;
      }

      &-container {
        padding: 0.3125rem 0;
        word-break: break-all;
      }
    }

    &-document-list-item {
      padding: 0.625rem 0 0.625rem 0;
      border-bottom: 0.0625rem solid $grey;
    }

    &-link {
      font-size: 3.3475rem !important;
    }
  }
}
