// self host the fonts

@font-face {
  font-family: "22_iconfont";
  src:
    url(./../assets/fonts/22_iconfont.woff2) format("woff2"),
    url(./../assets/fonts/22_iconfont.woff) format("woff"),
    url(./../assets/fonts/22_iconfont.ttf) format("truetype"),
    url(./../assets/fonts/22_iconfont.svg) format("svg");
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  src: url(./../assets/fonts/nunito-sans-v3-latin-300.eot);
  src:
    local("Nunito Sans Light"),
    local("NunitoSans-Light"),
    url(./../assets/fonts/nunito-sans-v3-latin-300.eot?#iefix)
      format("embedded-opentype"),
    url(./../assets/fonts/nunito-sans-v3-latin-300.woff2) format("woff2"),
    url(./../assets/fonts/nunito-sans-v3-latin-300.woff) format("woff"),
    url(./../assets/fonts/nunito-sans-v3-latin-300.ttf) format("truetype"),
    url(./../assets/fonts/nunito-sans-v3-latin-300.svg#NunitoSans) format("svg");
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: url(./../assets/fonts/nunito-sans-v3-latin-regular.eot);
  src:
    local("Nunito Sans Regular"),
    local("NunitoSans-Regular"),
    url(./../assets/fonts/nunito-sans-v3-latin-regular.eot?#iefix)
      format("embedded-opentype"),
    url(./../assets/fonts/nunito-sans-v3-latin-regular.woff2) format("woff2"),
    url(./../assets/fonts/nunito-sans-v3-latin-regular.woff) format("woff"),
    url(./../assets/fonts/nunito-sans-v3-latin-regular.ttf) format("truetype"),
    url(./../assets/fonts/nunito-sans-v3-latin-regular.svg#NunitoSans) format("svg");
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: url(./../assets/fonts/nunito-sans-v3-latin-600.eot);
  src:
    local("Nunito Sans SemiBold"),
    local("NunitoSans-SemiBold"),
    url(./../assets/fonts/nunito-sans-v3-latin-600.eot?#iefix)
      format("embedded-opentype"),
    url(./../assets/fonts/nunito-sans-v3-latin-600.woff2) format("woff2"),
    url(./../assets/fonts/nunito-sans-v3-latin-600.woff) format("woff"),
    url(./../assets/fonts/nunito-sans-v3-latin-600.ttf) format("truetype"),
    url(./../assets/fonts/nunito-sans-v3-latin-600.svg#NunitoSans) format("svg");
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-Black.ttf) format("truetype"),
    local("WorkSans-Black"),
    local("WorkSans Black");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-BlackItalic.ttf) format("truetype"),
    local("WorkSans-BlackItalic"),
    local("WorkSans BlackItalic");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-ExtraBold.ttf) format("truetype"),
    local("WorkSans-ExtraBold"),
    local("WorkSans ExtraBold");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-ExtraBoldItalic.ttf) format("truetype"),
    local("WorkSans-ExtraBoldItalic"),
    local("WorkSans ExtraBoldItalic");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-Bold.ttf) format("truetype"),
    local("WorkSans-Bold"),
    local("WorkSans Bold");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-BoldItalic.ttf) format("truetype"),
    local("WorkSans-BoldItalic"),
    local("WorkSans BoldItalic");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-SemiBold.ttf) format("truetype"),
    local("WorkSans-SemiBold"),
    local("WorkSans SemiBold");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-SemiBoldItalic.ttf) format("truetype"),
    local("WorkSans-SemiBoldItalic"),
    local("WorkSans SemiBoldItalic");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-Medium.ttf) format("truetype"),
    local("WorkSans-Medium"),
    local("WorkSans-Medium");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-MediumItalic.ttf) format("truetype"),
    local("WorkSans-MediumItalic"),
    local("WorkSans MediumItalic");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-Regular.ttf) format("truetype"),
    local("WorkSans-Regular"),
    local("WorkSans Regular");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-Italic.ttf) format("truetype"),
    local("WorkSans-Italic"),
    local("WorkSans Italic");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-Light.ttf) format("truetype"),
    local("WorkSans-Light"),
    local("WorkSans Light");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-LightItalic.ttf) format("truetype"),
    local("WorkSans-LightItalic"),
    local("WorkSans LightItalic");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-ExtraLight.ttf) format("truetype"),
    local("WorkSans-ExtraLight"),
    local("WorkSans ExtraLight");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-ExtraLightItalic.ttf) format("truetype"),
    local("WorkSans-ExtraLightItalic"),
    local("WorkSans ExtraLightItalic");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-Thin.ttf) format("truetype"),
    local("WorkSans-Thin"),
    local("WorkSans Thin");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src:
    url(./../assets/fonts/work-sans/WorkSans-ThinItalic.ttf) format("truetype"),
    local("WorkSans-ThinItalic"),
    local("WorkSans ThinItalic");
  font-style: italic;
  font-weight: 100;
}
