// lumos corporate design styles

// colors
:root {
  --primary-color: #157676; //#a91033;
  --primary-accent-1: #153c3c; //#002F37;
  --secondary-color: #153c3c; //#002F37
  --secondary-accent-1: #999; //#999;
  --secondary-accent-2: #ebebeb; //#ebebeb;
  --lumos-dark-blue-1: #157676; //#325c76
  --lumos-dark-blue-2: #47a8a8; //#346b8e
  --red: #b46b6a; //#FF0000
  --red-dark: #a91033; //#a91033
  --red-accent-1: #ffcdd2; //#ffcdd2
  --red-accent-2: #ef9a9a; //#ef9a9a
  --red-accent-3: #fcddd9; //#fcddd9
  --red-light: #ffebee; //#ffebee
  --gray-2: #9e9e9e; //#9e9e9e
  --font-family: "Source Sans 3", sans-serif; //"Nunito Sans", "Arial", sans-serif;
  --text-color: #2b2b2b; //#001115;
  --text-color-filled: #ffffff;
}

$primary: var(--primary-color);
$primary-accent-1: var(--secondary-color); // hover and focus
$secondary: var(--secondary-color);
$secondary-accent-1: var(--secondary-accent-1); // hover and focus
$secondary-accent-2: var(--secondary-accent-2); // hover and focus

$orange: var(--secondary-color);
$orange-accent-1: var(--lumos-dark-blue-1);
$berry: var(--secondary-color); // links
$berry-accent-1: var(--lumos-dark-blue-2); // hover and focus

$red: var(--red);
$red-dark: var(--red-dark);
$red-accent-1: var(--red-accent-1);
$red-accent-2: var(--red-accent-2);
$red-accent-3: var(--red-accent-3);
$red-light: var(--red-light);

// secondary colors
$green: #66ad31;
$purple: #522583;
$grey: var(--gray-2);

// misc
$font-text: var(--font-family);
$text-color: var(--text-color-filled);

// put all colors in a list of colors to use in custom-styles.module
$colors: (
  "primary": $primary,
  "primary-accent-1": $primary-accent-1,
  "secondary": $secondary,
  "secondary-accent-1": $secondary-accent-1,
  "secondary-accent-2": $secondary-accent-2,
  "orange": $orange,
  "orange-accent-1": $orange-accent-1,
  "berry": $berry,
  "berry-accent-1": $berry-accent-1,
  "red": $red,
  "red-dark": $red-dark,
  "red-accent-1": $red-accent-1,
  "red-accent-2": $red-accent-2,
  "red-accent-3": $red-accent-3,
  "red-light": $red-light,
  "green": $green,
  "purple": $purple,
  "grey": $grey,
  "text-color": $text-color,
);
