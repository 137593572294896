.RegistrationPage {
  width: 100%;

  label {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.RegistrationForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.RegistrationHeading {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding-left: 2.5rem;
  padding-bottom: 1.25rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--text-color);
  text-transform: uppercase;
}

.RegistrationAdress {
  display: flex;
  justify-content: flex-start;

  h3 {
    padding-top: 1.875rem;
    font-size: 1.1rem;
    font-weight: 300;
    color: var(--text-color);
    text-transform: uppercase;
  }
}

.RegistrationText {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  text-align: start;
  padding: 1.25rem 0 2.5rem 0;
}

.registrationButton {
  button {
    width: 100%;
    color: white;
    padding: 0.5rem;
    margin-top: 1.25rem;
  }
}

.forgot-password {
  //display: flex;
  //width: 100%;
  //justify-content: end;
  //a{
  //  width: 100%;
  //  display:flex;
  //  justify-content: end;
}

@media (max-width: 48rem) {
  .RegistrationForm {
    width: 80%;
  }

  .RegistrationText {
    width: 80%;
  }

  .RegistrationHeading {
    width: 80%;
  }
}