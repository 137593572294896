@import "../../../styles/_custom-mixins.scss";
@import "../../../styles/variables";

.myinvoices {
  &-description {
    ul {
      @include custom-lumos-list;
    }

    ol {
      @include custom-lumos-ordered-list;
    }
  }

  &-test{
    font-weight: 900;
  }

  &-list {
    &-header {
      @media screen and (min-width: 60rem) {
        font-size: 1rem;
      }
      @media screen and (max-width: 75rem) {
        font-size: 0.85rem;
      }
      font-weight: 43.75rem;

      border-bottom: 0.0625rem solid $grey;
      margin-top: 0.875rem !important;
      margin-bottom: 0.875rem !important;
      padding: 1rem 0;
    }

    &-item {
      padding: 1rem 0;
    }

    &-item:hover {
      background-color: $secondary-accent-2;
    }

    &-item a {
      color: #000;
    }

    &-item a .svg-icon {
      vertical-align: top;
    }
  }

  &-details {
    &-headline {
      font-size: 1.17rem;
      font-weight: bold;
      margin-top: 0.625rem;
    }

    &-property {
      &-label {
        font-weight: 43.75rem;
      }

      &-container {
        padding: 0.3125rem 0;
        word-break: break-all;
      }
    }

    &-document-list-item {
      padding: 0.625rem 0 0.625rem 0;
      border-bottom: 0.0625rem solid $grey;
    }

    &-document-download-icon {
      color: $primary !important;
    }

    &-document-download-icon:hover {
      color: $secondary !important;
    }

    &-link {
      font-size: 3.4375rem !important;
    }

    &-link:hover {
      color: $secondary;
    }
  }
}

#table-row {
  th {
    font-weight: 900 !important;
  }
  td {
    text-align: center !important;
  }
}