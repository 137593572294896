.BaseForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.BaseField {
  width: 100%;

  label {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.Form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
}

.Heading {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding-left: 2.5rem;
  padding-bottom: 1.25rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--text-color);
  text-transform: uppercase;
}

.Text {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  text-align: start;
  padding: 1.25rem 0 2.5rem 0;
}

.Button {
  button {
    width: 100%;
    color: white;
    padding: 0.5rem;
    margin-top: 1.25rem;
  }
}
