@import "../../../../styles/variables";

.documents-list {
  &-itemsHeader {
    border-bottom: 0.06rem solid $grey;
    padding-bottom: 0.62rem;
  }

  &-item {
    padding: 0.62rem 0 0.62rem 0;
    border-bottom: 0.06rem solid $grey;

    &-col {
      margin: auto !important;
    }

    &-delButton {
      cursor: pointer;
      color: $red;
    }

    &-delButton-mono {
      cursor: pointer;
      color: #000;
    }

    &-delButtonDisabled {
      cursor: pointer;
      color: $grey;
    }

    &-before {
      text-align: center;
      border-right: 0.06rem solid $grey;
    }

    &-after {
      text-align: center;
    }

    &-noteditable {
      background-color: #f8f8f8;
    }
  }

  &-item:hover {
    background-color: $secondary-accent-2;
  }

  &-downloadIcon {
    fill: $text-color !important;
    margin-right: 0.62rem;
  }

  &-downloadIcon:hover {
    fill: $grey !important;
  }
}

.MuiDropzoneArea-text {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem !important;
}

.MuiDropzoneArea-root {
  min-height: 12.5rem !important;
}

.MuiDropzoneArea-root:focus {
  outline: none;
}
