.event-list {
  &-separator {
    margin: 0.625rem 0 1.25rem 0 !important;
  }
}

.fill-full-width {
  width: 100%;
  width: stretch;
}
