.checkoutButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 12.5rem;
  button:first-of-type {
    width: 10%;
    height: 1.875rem;
    background-color: var(--secondary-color);
    color: white;
    border-width: 0.0625rem;
  }
  button {
    width: 10%;
    height: 1.875rem;
    background-color: var(--primary-color);
    color: white;
    border-width: 0;
  }
}
.checkout-btn-box {
  width: 15rem;
  @media screen and (max-width: 37.5rem) {
    width: 100%;
  }
}

.checkout-btn-wrapper {
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 37.5rem) {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 1rem;
  }
}

.grid {
  @media screen and (max-width: 37.5rem) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
