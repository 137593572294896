.no-match {
  position: relative;
  min-height: 21.875rem;

  &-banner {
    width: 100%;
    height: 21.875rem;
    object-fit: cover;
    /* Do not scale the image */
    object-position: center;
    /* Center the image within the element */
  }

  &-text {
    position: absolute;
    top: 0;
    padding-bottom: 2.5rem;
    padding-top: 10rem;
  }

  &-title {
    display: inline;
    position: relative;
    max-width: 70%;
    font-size: 2rem;
    line-height: 1.9;
    line-height: 2.2;
    padding: 0.4rem;
    color: white;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  &-link {
    &:hover {
      color: var(--text-color) !important;
    }
  }
}
