.ElearningContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 7.75rem;
}
.ElearningContainerButtons {
  display: flex;
  width: 50%;
  justify-content: space-between;
  button:first-of-type {
    width: 25%;
    height: 1.875rem;
    background-color: #fdfefe;
    color: var(--primary-color);
    border-width: 0.0625rem;
  }
  button {
    width: 25%;
    height: 1.875rem;
    background-color: var(--primary-color);
    color: #f3f9f8;
    border-width: 0;
  }
}
.Elearning {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  span {
    color: #808080;
    padding-bottom: 1.5rem;
    text-align: center;
  }
}
.ElearningMappingContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 16.875rem;
  align-items: flex-start;
}

.ElearningAnswers {
  display: flex;
  align-items: center;
  color: #808080;
  width: 100%;
  justify-content: flex-start;
  height: 3.125rem;
  padding: 0.25rem;
  margin: 0.5rem 0 0.5rem 0;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: #808080 !important;

  button {
    display: flex;
    justify-content: flex-start;
    font-size: smaller;
    flex-wrap: wrap;
    background-color: white;
    width: 80%;
    border-width: 0;
    color: #808080;
  }
}
.ElearningIntro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    width: 15%;
    font-size: 1.125rem;
    margin-top: 0.375rem;
    background-color: var(--primary-color);
    color: #f3f9f8;
    height: 2rem;
    border-width: 0;
  }
}
.MultipleChoice {
  border-width: 0;
  width: 48%;
}
.Normal {
  border-width: 0.125rem;
}

.ElearningAnswersMultipleChoice {
  display: flex;
  align-items: center;
  color: #808080;
  width: 63%;
  justify-content: flex-start;
  height: 2.75rem;
  padding: 0.25rem;
  margin: 0.5rem 0 0.5rem 0;
  border-width: 0;
  border-color: #808080 !important;

  button {
    display: flex;
    justify-content: flex-start;
    font-size: smaller;
    flex-wrap: wrap;
    text-align: left;
    margin-left: 0 !important;
    background-color: white;
    width: 90%;
    border-width: 0;
    color: #808080;
  }
}
.finishedElearning {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12.5rem;
  text-align: center;
  div {
    display: flex;
    width: 30%;
    justify-content: flex-end;
    button {
      width: 40%;
      height: 1.875rem;

      margin-top: 2.5rem;
      background-color: var(--primary-color);
      color: #f3f9f8;
      border-width: 0;
    }
  }
}
