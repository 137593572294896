@import "../../../../../styles/variables";

.learning-events-overview {
  &-infolink,
  &-infolink-mono {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
    border-bottom: 0.125rem solid transparent !important;
  }
  &-infolink-mono:hover {
    border-bottom: 0.125rem solid #000 !important;
  }
  &-infolink span,
  &-infolink-mono span {
    color: $primary;
  }
  &-infolink:hover span {
    color: $primary-accent-1;
  }
  &-headline {
    font-size: 1.75rem;
    font-weight: bold;
    margin-top: 3.125rem;
    margin-bottom: 1.5625rem;
  }
  &-infotext {
    margin-bottom: 2.5rem;
    margin-right: 20vw;
  }
}
@media screen and (max-width: 36.5rem) {
  .learning-events-overview {
    &-infotext {
      margin-right: 0;
    }
  }
}
