@import "../../../../styles/custom-mixins";

.DraftEditor-editorContainer {
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    display: inline;
  }

  ul {
    @include custom-lumos-list;
  }

  ol {
    @include custom-lumos-ordered-list;
  }

  // TODO: check if this is needed anymore (uses global list styles)
  // li {
  //   list-style-position: outside;
  // }

  // ul {
  // padding-left: 1.25rem;
  // list-style-type: square;
  // list-style-position: outside;
  // }

  // ol {
  // padding-left: 1.25rem;
  // list-style-position: outside;
  // }
}
