@import "../../../styles/variables";

.checkout-atb-box {
  margin: 0.625rem 0rem !important;
  padding: 0.625rem;
  background-color: #f8f8f8;
  border: 0.0625rem solid $grey;
}

.confirm-condition-box{
  @extend .checkout-atb-box;
  margin-top: 0rem !important;
}

@media screen and (max-width: 37.5rem) {
  .MuiFilledInput-root.Mui-error::before {
    display: none !important;
  }
}

.checkout-btn-box-page {
  width: 15rem;
  @media screen and (max-width: 37.5rem) {
    width: 100%;
  }
}

.checkout-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 37.5rem) {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
