@import "custom-mixins";

/* 
---------------- BUTTONS ----------------
*/

.btn-icon,
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-nav-go-to::after {
  content: "\3009";
  font-family: "Font Awesome 5 Free";
  font-weight: 500;
  display: inline;
  margin: 0 0.5rem;
}

.btn-nav {
  text-align: initial;
  background-color: var(--secondary-accent-2);
  color: unset;
  box-shadow: none;
}

.btn-nav > i {
  color: var(--gray-2) !important;
}

.btn-nav:hover > i {
  color: white !important;
}

.btn-nav:hover,
.btn-nav:focus {
  background-color: $primary;
  color: white !important;
}

.btn-block {
  width: 100%;
}

// misc

// TODO: refactor styles
.mono-color {
  &-header-title {
    position: relative;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
  }

  // &-header-title:before {
  //   background-color: #000;
  //   content: "";
  //   width: .625rem;
  //   height: 2.1875rem;
  //   align-items: center;
  //   display: block;
  //   position: absolute;
  //   -webkit-transform: skew(20deg);
  //   -ms-transform: skew(20deg);
  //   transform: skew(20deg);
  //   left: 0;
  //   top: -0.125rem;
  // }
}

.header-title {
  position: relative;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}

// .header-title:before {
//   content: "";
//   width: .625rem;
//   height: 2.1875rem;
//   background-color: #f49325;
//   align-items: center;
//   display: block;
//   position: absolute;
//   -webkit-transform: skew(20deg);
//   -ms-transform: skew(20deg);
//   transform: skew(20deg);
//   left: 0;
//   top: -0.125rem;
// }

@media only screen and (max-width: 25rem) {
  .header-title h4 {
    font-size: 1.1rem;
    padding-left: 0;
  }

  .header-title:before {
    width: 0.5rem;
    height: 1.5625rem;
  }
}

/*
---------------- LISTS ----------------
*/

.list {
  @include custom-lumos-list;
}

.checkmark-list {
  @include custom-lumos-checkmark-list;
}

.ordered-list {
  @include custom-lumos-ordered-list;
}

ul,
ol {
  padding: 0;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

/*
---------------- ICONS ----------------
*/

.svg-icon .stroke-color-main {
  stroke-width: 0.6875rem;
  fill: none;
}

hr {
  color: $grey;
}
