@import "../../../styles/variables";

#footer {
  margin-top: 3.125rem;
}

footer {
  display: block;
}

.footer-upper {
  position: relative;
}

.footer-bottom {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
}

.info-box {
  width: 28.125rem;
  padding: 2.5rem 1.25rem;
  color: white;
  background-color: rgba(60, 60, 60, 0.8);
  pointer-events: all;
  position: relative;
  z-index: 1;
}

.map {
  z-index: 0;
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
}

.leaflet-pane {
  position: relative;
  bottom: 0;
  top: 0;
  z-index: 400;
}

.leaflet-container {
  min-height: 25rem;

  a.leaflet-popup-close-button {
    color: $primary;
  }
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-popup-content-wrapper {
  border-radius: 0;
  box-shadow:
    0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2),
    0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14),
    0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12);
}
