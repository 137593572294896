.header-profile {
  &-role-text {
    font-size: small;
  }

  &-role-name {
    font-size: large;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 40vw;
  }

  &-favorite-icon {
    font-size: 3.12rem !important;
    color: var(--gray-2);
    margin-bottom: -0.15em;
    cursor: pointer;
  }

  &-role-picture {
    font-size: large;
    cursor: pointer;
  }

  &-icons {
    color: var(--gray-2) !important;
    font-size: 3.12rem !important;
  }

  @media screen and (max-width: 25rem) {
    &-role-picture {
      display: none;
    }
  }
}
